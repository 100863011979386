.container {
  /* max-width: 100%; */
  max-width: 95%;
}
#vanta {
  /* height: 100vh; */
  width: 100vw;
  position: sticky;
  top: 0;
  bottom: 0;
  z-index: -1;
}
.shadow-container {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
  padding: 10px;
}
.title-component {
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  font-size: 20px;
}
.text-component {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 16px;
}
.text-it {
  font-style: italic;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 20px;
}
.text-small {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 13px;
  color: #faf7f7;
  /* text-align: center; */
}
